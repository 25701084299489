import React from "react"
import { graphql } from "gatsby"

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import VideoPage, { VideoType } from "@/components/VideoPage"

type PageProps = {
  data: {
    sanityVideo: VideoType
  }
}

export const pageQuery = graphql`
query TestVideo6Query {
  sanityVideo(position: {eq: 6}) {
    title
    description
    placeholder {
      asset {
        url
      }
    }
    videoUrl
  }
}
`

const Video6Test: React.FC<PageProps> = ({ data }) => {
  return (
    <Layout
      sectionOne={
        <>
          <video
            id="bgvid"
            className="absolute inset-0 w-full h-full object-cover -z-50 sm:hidden"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src="/videos/rbai_giving_day_mobile.mp4" type="video/mp4" />
          </video>
          <video
            id="bgvid"
            className="absolute inset-0 w-full h-full object-cover -z-50  hidden sm:block"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src="/videos/rbai_giving_day.mp4" type="video/mp4" />
          </video>
          <div className="container text-white pt-60 pb-4 flex flex-col gap-4 items-center text-center">
            <div>
              <h1 className="pb-2 md:pb-12 responsive-heading">
                {data.sanityVideo.title}
              </h1>
              <div className="pt-8 flex justify-center">
                <img src="/images/down.png" alt="Down arrow" />
              </div>
            </div>
          </div>
        </>
      }
    >
      <SEO title={data.sanityVideo.title} />
      <VideoPage video={{ ...data.sanityVideo, videoUrl: "2b1l9ytsrx" }} />
    </Layout>
  )
}

export default Video6Test
